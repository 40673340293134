import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Fetch, FetchFilter, applyMacros, mergeFetch } from "shared/fetch";
import { ILookupValue, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { checkCommandPermissions, humanize, IAppConfig, IList, IOption, IQuickFilter, MetaContext, tryLocalize } from "../AppState";
import { getDropTarget, useDragProps } from "../components/draggable";
import { IconButton } from "../components/IconButton";
import { makeArrayType, makeBoolType, makeButtonType } from "../customize/ConfigureFormField";
import { useSessionState } from "../hooks/useSessionState";
import { IModalContext, ModalContext, showMenu } from "../modal/Modal";
import { configureFormFieldDialog } from "../objectForm/Configure";
import { LookupInput } from "../objectForm/LookupInput";
import { showFetchDialog } from "../customize/FetchDialog";
import { dateToString } from "../utils/objectHelpers";
import { formatNumericValue } from "../formatters";
import { NumberInput } from "../components/NumberInput";
import { showRichOptionsDialog } from "../customize/Metadata";

// [Open] [Scheduled] [Closed] [All]

const DateOptionsConfig: { [key: string]: { label: string, icon: string } } = {
	"today": { label: "Today", icon: "calendar-day" },
	"tomorrow": { label: "Tomorrow", icon: "calendar-day" },
	"this-week": { label: "Week", icon: "calendar-week" },
	"next-week": { label: "Next Week", icon: "calendar-week" },
	"this-month": { label: "Month", icon: "calendar-days" },
}

const getFilterStyle = (isSelected: boolean|undefined, optionColor?: string) => {
	const style = {} as any;
	if (!isSelected) {
		style.color = "gray";
		style.border = "1px solid var(--border-color3)";
	} else {
		const c = optionColor || "#0071eb";
		style.color = c;
		style.border = "1px solid " + c;
		if (c.startsWith("#")) {
			style.backgroundColor = c + ((c.length === 4) ? "1" : "10"); // turn #aaa -> #aaa4 
		}
	}
	return style;
}

export const QuickFilterOptions = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[])=>void }) => {

	const metadata = useContext(MetaContext);
	const filter = props.filter;

	// const meta = metadata.objects.find(x => x.logicalName === filter.objectName);
	// if (!meta) throw Error("Object not found:" + filter.objectName);
	// const propMeta = meta.properties.find(x => x.logicalName === filter.propertyName);
	// if (!propMeta) throw Error("Field not found: " + filter.objectName + "." + filter.propertyName);
	const propMeta = props.propMeta;
	
	const options = useMemo(() => {
		let options: IOption[] = [];
		let dirOpts = filter.directOptions;
		if ((!dirOpts || !dirOpts.length || !dirOpts[0]) && filter.type === "owner")
			dirOpts = ["My"];
		
		if (filter.richOptions) {
			options = filter.richOptions
		} else if (dirOpts) {
			options = (dirOpts as string[]).map(x => ({ value: x }));
			if (filter.type === "date") {
				for (const o of options) {
					const rich = DateOptionsConfig[o.value];
					if (rich) {
						o.label = rich.label;
						o.icon = rich.icon;
					} else {
						o.label = humanize(o.value.replaceAll("-", " "));
					}
				}
			} else if (filter.type === "owner") {
				options[0].icon = "universal-access";
			}
			for (const o of options) {
				o.label = tryLocalize(metadata, o.label || o.value, o.value);
			}
		} else if (propMeta.richOptions) {
			options = [...propMeta.richOptions];
		} else if (propMeta.options) {
			options = propMeta.options.map(x => ({ value: x }));
		}
		const inc = filter.includedOptions;
		if (inc) {
			options = options.filter(x => inc.indexOf(x.value) >= 0);
		}
		const exc = filter.excludedOptions;
		if (exc) {
			options = options.filter(x => exc.indexOf(x.value) < 0);
		}
		return options;
	}, [filter, propMeta]);

	const selected = props.selected;

	const updateSelected = useCallback((toggleOption: string) => {
		let prev = selected || [];
		const index = prev.indexOf(toggleOption);
		if (index >= 0)
			prev = prev.filter(x => x !== toggleOption);
		else if (filter.multiSelect)
			prev = prev = prev.concat(toggleOption);
		else // single select
			prev = [toggleOption];
		
		if (prev.length === 0 && filter.mustSelect) {
			const defaultOption = filter.defaultOption || toggleOption;
			prev = [defaultOption];
		}
		props.onChange(filter, prev);
	}, [filter, selected]);

	const getOptionStyle = (o: IOption) => {
		return getFilterStyle(selected && selected.indexOf(o.value) >= 0, o.color);
	}

	if (filter.optionsFormat === "select") {
		let opts = [{ value: "__select_all__", label: propMeta.displayName! + " *" } as IOption].concat(options);
		const selectedLabel = options.filter(o => (selected && selected.indexOf(o.value) >= 0)).map(o => (o.label || o.value)).join(",") || opts[0].label;

		const style = getOptionStyle(options.find(o => (selected && selected.indexOf(o.value) >= 0)) || opts[0]);

		return <div className="quickFilterOptions">
			<div className="quickFilterOption quickFilterLookup quickFilterInput" style={style}>
				{/* <span style={{ color: "#ccc" }}>Skupina:</span> */}
				<div className="selectInput">
					<div className="selectInputValue">{selectedLabel}</div>
					<div className="selectInputDropdown"><i className="fa fa-chevron-down"></i></div>
					<select style={{ opacity: "0", gridArea: "1/1" }} value={selected && selected[0]}
						onChange={e => e.target.value === "__select_all__" ? props.onChange(filter, []) : updateSelected(e.target.value)}>
						{opts.map((x, i) => (<option key={"o" + x.value} value={x.value}>{x.label || x.value}</option>))}
					</select>
				</div>
			</div>
		</div>
	}

	return <div className="quickFilterOptions">
		{options.map(o => {
			const style = getOptionStyle(o);
			
			return <IconButton key={o.value} className="quickFilterOption" style={style} label={o.label || o.value} icon={o.icon||""} onClick={e => updateSelected(o.value)} />
		})}
	</div>
}

const QuickFilterDay = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[]) => void }) => {

	const filter = props.filter;
	const selected = props.selected;

	const style = getFilterStyle(!!(selected && (selected[0] || selected[1])));

	const dateValue = (selected && selected[0]) ? dateToString(new Date(selected[0]), false) : "";

	const onDateChanged = (e: any) => {
		const dateInput = e.target as HTMLInputElement;
		if (dateInput) {
			let newSelected: string[] = [];
			if (dateInput.value) {
				const z = new Date(dateInput.value);
				if (!isNaN(z as any)) {
					newSelected = [z.toISOString()];
				}
			}
			props.onChange(filter, newSelected);
		}
	}
	return <div className="quickFilterOptions">
		<div className="quickFilterOption quickFilterLookup quickFilterBase quickFilterDay" style={style}>
			<label>{filter.icon && <i className={"fa " + filter.icon} />}
				{filter.label && <span>{filter.label}</span>}
				<input type="date" value={dateValue} onChange={onDateChanged} />
				</label>
		</div>
	</div>
}

const QuickFilterDateRange = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[]) => void }) => {

	const filter = props.filter;
	const selected = props.selected;

	const style = getFilterStyle(!!(selected && (selected[0] || selected[1])));

	let startRange = "-∞";
	let endRange = "∞";

	if (selected && selected.length > 0) {
		if (!selected[0]) {
			if (selected[1])
				endRange = new Date(selected[1]).toLocaleDateString();
		} else {
			const s = new Date(selected[0]);
			startRange = s.toLocaleDateString();
			if (selected[1]) {
				const e = new Date(selected[1]);
				if (s.getFullYear() === e.getFullYear()) {
					if (s.getMonth() === e.getMonth()) {
						startRange = s.toLocaleString([], { day: 'numeric' });
					} else {
						startRange = s.toLocaleString([], { month: 'numeric', day: 'numeric' });
					}
				}
				endRange = e.toLocaleDateString();
			}
		}
	}

	const dateInputRef = useRef<HTMLInputElement>(null);

	const onStartClick = (rangeIndex: number) => {
		const dateInput = dateInputRef.current;
		if (dateInput) {
			let n = new Date();
			n = new Date(n.getFullYear(), n.getMonth(), n.getDate() + 1, 6, 0, 0);
			const dt = selected && selected[rangeIndex];
			const dts = dateToString(dt ? new Date(dt) : n, true);
			dateInput.value = dts;
			//dateInput.defaultValue = "2023-04-01T09:15";//dateToString(n, true);
			const picker = (dateInput as any);
			if (picker.showPicker)
				picker.showPicker();
			dateInput.onchange = (pickerEvent: any) => {
				let newSelected = new Array<string>(2);
				if (selected)
					newSelected[1 - rangeIndex] = selected[1 - rangeIndex];
				newSelected[rangeIndex] = dateInput.value ? new Date(dateInput.value).toISOString() : "";
				if (!newSelected[0] && !newSelected[1])
					newSelected = [];
				props.onChange(filter, newSelected);
			}
		}
	}

	return <div className="quickFilterOptions">
		<div className="quickFilterOption quickFilterLookup quickFilterBase quickFilterDateRange" style={style}>
			{filter.icon && <i className={"fa " + filter.icon} />}
			{filter.label && <span>{filter.label}</span>}
			<button className="buttonReset" onClick={e=>onStartClick(0)}>{startRange}</button>
			<span>&nbsp;↔&nbsp;</span>
			<button className="buttonReset" onClick={e=>onStartClick(1)}>{endRange}</button>
			<input ref={dateInputRef} type="datetime-local" style={{ width:"0", height:"0",opacity:"0", position:"absolute", right:"0", bottom:"0" }}/>
		</div>
	</div>
}

const QuickFilterNumericRange = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[]) => void }) => {

	const filter = props.filter;
	const selected = props.selected;

	const style = getFilterStyle(!!(selected && (selected[0] || selected[1])));

	const onRangeChanged = (rangeIndex: number, value: any) => {
		
		let newSelected = new Array<string>(2);
		if (selected)
			newSelected[1 - rangeIndex] = selected[1 - rangeIndex];
		newSelected[rangeIndex] = value;
		if (!newSelected[0] && !newSelected[1])
			newSelected = [];
		props.onChange(filter, newSelected);
	}

	return <div className="quickFilterOptions">
		<div className="quickFilterOption quickFilterLookup quickFilterBase quickFilterNumericRange" style={style}>
			{filter.icon && <i className={"fa " + filter.icon} />}
			{filter.label && <span>{filter.label}</span>}
			<NumberInput onChange={val => onRangeChanged(0, val)} precision="0" allowEmpty={true} value={((selected && selected[0]) || "")} inputProps={{ placeholder: "-∞" }} />
			<span>&nbsp;↔&nbsp;</span>
			<NumberInput onChange={val => onRangeChanged(1, val)} precision="0" allowEmpty={true} value={((selected && selected[1]) || "")} inputProps={{ placeholder: "∞" }} />
		</div>
	</div>
}

const QuickFilterText = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[]) => void }) => {

	const filter = props.filter;
	const selected = props.selected;

	const style = getFilterStyle(!!(selected && selected[0]));

	const placeholder = filter.label ? undefined : props.propMeta.displayName!; 
	const inputId = filter.name + "__qfid";

	return <div className="quickFilterOptions">
		<div className="quickFilterOption quickFilterLookup quickFilterBase quickFilterText" style={style}>
			{filter.icon && <i className={"fa " + filter.icon} />}
			{filter.label && <label htmlFor={inputId}>{filter.label}</label>}
			<input type="text" value={(selected && selected[0]) || ""} onChange={e=>props.onChange(filter, [e.target.value])}
				placeholder={placeholder} id={inputId} />
		</div>
	</div>
}

export const QuickFilterLookup = (props: { propMeta: IMetaProperty, filter: IQuickFilter, selected?: string[], onChange: (self: IQuickFilter, options: string[]) => void }) => {

	const metadata = useContext(MetaContext);
	const filter = props.filter;
	const selected = props.selected;

	const propMeta = props.propMeta;
	
	const updateSelected = useCallback((newValue: any) => {
		const prev: any[] = [];
		if (newValue)
			prev.push(JSON.stringify(newValue));
		props.onChange(filter, prev);
	}, [filter, selected]);

	let lookup: ILookupValue = null as any;
	const emptyText = (filter.label || propMeta.displayName!) + ": *"; // fixme: localize!
	let isSelected = selected && selected.length > 0;
	if (isSelected) {
		lookup = JSON.parse(selected![0]) as ILookupValue;
	}
	const style = getFilterStyle(isSelected);

	const getRecordProperty = (field: string) => {
		if (field === "ownerid")
			return { id: metadata.user.id, name: "systemuser", label: metadata.user.name };
		return undefined;
	}

	return <div className="quickFilterOptions">
		<div className="quickFilterOption quickFilterLookup" style={style}>
			<LookupInput canCreate={false} extraFetch={filter.extraFetch} canOpen={false} value={lookup} emptyText={emptyText} onChange={updateSelected} getRecordProperty={getRecordProperty} propMeta={propMeta} />
			{/* <IconButton key={o.value} className="quickFilterOption" style={style} label={o.label || o.value} icon={o.icon||""} onClick={e => updateSelected(o.value)} /> */}
		</div>
	</div>
}

const mm = (title: string, sub: string) => {
	return <div style={{display:"flex", justifyContent:"space-between"}}><span>{title}</span><span style={{color:"gray", marginLeft:"2em"}}>{sub}</span></div>
}

export interface IQuickFilterState {
	selected: { [key: string]: string[] | undefined },//(string[] | undefined)[];
	setSelected: (filter: IQuickFilter, selectedOptions: string[]) => void;

	userFilters: IQuickFilter[];
	setUserFilters: (v: IQuickFilter[] | ((prev: IQuickFilter[]) => IQuickFilter[])) => void;
}

const getFilterElement = (x: IQuickFilter) => {
	switch (x.type) {
		case "lookup": return QuickFilterLookup;
		case "day": return QuickFilterDay;
		case "daterange": return QuickFilterDateRange;
		case "numericrange": return QuickFilterNumericRange;
		case "number":
		case "text": return QuickFilterText;
		default: return QuickFilterOptions;
	}
}

const createNewFilterMenu = async (modal: IModalContext, metadata: IAppConfig,
	objectName: string, targetElement: HTMLElement, addUserFilter: boolean = false) => {
	const props = [...metadata.objects.find(x => x.logicalName === objectName)?.properties || []];
	const propsLength = props.length;
	if (true) {
		props.push({ logicalName: "__af__", displayName: "Advanced Find", type: 0, flags: 0 });
	}
	if (!addUserFilter) {
		props.push({ logicalName: "__auf__", displayName: "Add User Filter", type: 0, flags: 0 });
	}

	const c = await showMenu(modal, targetElement, props.map(x => mm(x.displayName || x.logicalName, x.logicalName)));
	const propMeta = props[c];
	let f: IQuickFilter = {
		name: propMeta.logicalName,
		label: propMeta.displayName || humanize(propMeta.logicalName),
		type: "options",
		objectName: objectName,
		propertyName: propMeta.logicalName,
	}
	if ((propMeta.flags & MetaPropertyFlags.OptionSet) !== 0) {
		// options;
	}
	else if (propMeta.type === MetaPropertyType.DateTime) {
		f.type = addUserFilter ? "daterange" : "date";
		f.icon = "fa-calendar-days";
	}
	else if (propMeta.type === MetaPropertyType.Lookup) {
		f.type = "lookup";
	}
	else if (propMeta.type === MetaPropertyType.Decimal ||
		propMeta.type === MetaPropertyType.Money ||
		propMeta.type === MetaPropertyType.Integer ||
		propMeta.type === MetaPropertyType.Float) {
		f.type = "numericrange";
		f.icon = "fa-filter-circle-dollar";
	}
	else if (propMeta.type === MetaPropertyType.String) {
		f.type = "text";
		f.icon = "fa-magnifying-glass";
	}

	if (c === propsLength) {
		f.name = "custom " + new Date().valueOf();
		f.type = "custom";
		f.label = "Custom"; // compute from fetch conditions???
		await new Promise<void>((res, rej) => {
			const fetch: Fetch = { entity: { name: objectName } };
			showFetchDialog(modal, fetch, () => {
				f.extraFetch = fetch;
				f.label = prompt("Filter name", f.label) || f.label;
				res();
			})
		});
	} else if (c === propsLength + 1) {
		f.name = "AddUserFilter";
	}

	if (addUserFilter)
		f.optionsFormat = "select";
	return f;
}

const AddUserFilter = (props: {
	objectName: string,
	state: IQuickFilterState
}) => {

	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);

	const addUserFilter = async (e: React.MouseEvent) => {
		const f = await createNewFilterMenu(modal, metadata, props.objectName, e.target as HTMLElement, true);
		props.state.setUserFilters(x => x.concat(f));
	}

	const style = getFilterStyle(false);
	return <div className="quickFilterOptions" style={{ order: "1000" }}>
		<IconButton className="quickFilterOption" style={style} label={"+"} icon={"filter"} onClick={addUserFilter} />
	</div>
}

export const QuickFilterPanel = (props: { list: IList, customize: boolean, state: IQuickFilterState }) => {
	
	const { list, customize } = props;
	let filters = list.quickFilters || []
	const modal = useContext(ModalContext);
	const metadata = useContext(MetaContext);
	const [render, setRender] = useState(0);
	const forceRender = () => setRender(x => x + 1);
	
	if (!customize)
		filters = filters.concat(props.state.userFilters);

	const configureClick = useCallback(async(e: React.MouseEvent) => {
		e.preventDefault();
		e.stopPropagation();

		const item = getDropTarget(e);
		const itemIndex = +(item.dataset["drag_index"] || 0);
		let f = (list.quickFilters || [])[itemIndex];

		const attrs = ["name", "label", "icon", "permissions", "initialOption", "defaultOption", makeBoolType("multiSelect"),
			makeBoolType("mustSelect"), makeArrayType("directOptions"), makeArrayType("includedOptions"), makeArrayType("excludedOptions"),
			"mandatoryGroup", "minimumLength" 
		];

		attrs.push(makeButtonType("richOptions", "Rich Options", (a, b, item, setItem) =>
			showRichOptionsDialog(modal, { options: item.directOptions, richOptions: item.richOptions } as any, (newPropMeta) => {
				setItem({ ...item, directOptions: newPropMeta.options, richOptions: newPropMeta.richOptions });
			})));

		if (!f) {
			f = await createNewFilterMenu(modal, metadata, list.query.entity.name, item);
		}
		if (f.type === "date" || f.type === "daterange" || f.type === "day") {
			const ftype: IMetaProperty = { logicalName: "type", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["date", "daterange", "day"] };
			attrs.splice(0, 0, ftype);
		}  else if (f.type === "numericrange") {
			const ftype: IMetaProperty = { logicalName: "type", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["number", "numericrange"] };
			attrs.splice(0, 0, ftype);
		} else if (f.type === "lookup" || f.type === "custom") {
			const ftype: IMetaProperty = { logicalName: "type", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["lookup", "owner"] };
			attrs.splice(0, 0, ftype);

			const showCustomizeFetchDialog = async (e: any, info: any, item: IQuickFilter, modal: IModalContext) => {
				//find lookup targets from meta
				let fetch = item.extraFetch;
				if (!fetch) {
					const propMeta = (metadata.objects.find(x => x.logicalName === f.objectName)?.properties || []).find(x => x.logicalName === f.propertyName);
					if (propMeta && propMeta.targets && propMeta.targets.length === 1) {
						fetch = {
							entity: { name: propMeta.targets[0] }
						}
					}
				}
				if (fetch)
					showFetchDialog(modal, fetch, () => item.extraFetch = fetch);
			}
			attrs.push(makeButtonType("fetch", "Extra Fetch", (a, b, c) => showCustomizeFetchDialog(a, b, c, modal)));
		} else if(f.type !== "text") {
			const ftype: IMetaProperty = { logicalName: "optionsFormat", type: MetaPropertyType.String, flags: MetaPropertyFlags.OptionSet, options: ["radio", "select"] };
			attrs.splice(0, 0, ftype);
		}

		const showCustomizeSysFetchDialog = async (e: any, info: any, item: IQuickFilter, modal: IModalContext) => {
			let fetch = item.customFetch || { entity: { name: item.objectName } };
			showFetchDialog(modal, fetch, () => item.customFetch = fetch);
		}
		attrs.push(makeButtonType("customFetch", "Custom Fetch", (a, b, c) => showCustomizeSysFetchDialog(a, b, c, modal)));

		list.quickFilters = list.quickFilters || [];
		configureFormFieldDialog(modal, f, list, "quickFilters", attrs, e.target as HTMLElement, () => {
			forceRender();
		});
	}, [list]);

	const customizeMovePanel = useCallback((dragIndex: number, dropIndex: number) => {
		const field = filters.splice(dragIndex, 1)[0];
		filters.splice(dropIndex, 0, field);
		forceRender();
	}, [list, filters]);

	const dragEvents = useDragProps(customize, customizeMovePanel);
	const de = { ...dragEvents, onDragStart: undefined };

	const removeUserFilter = async (e: React.MouseEvent, userFilter: IQuickFilter) => {
		if (userFilter.type === "custom") {
			const d = await showMenu(modal, e.target as HTMLElement, ["Edit", "Rename", "Delete"]);
			if (d === 0) {
				const fetch = JSON.parse(JSON.stringify(userFilter.extraFetch!));
				showFetchDialog(modal, fetch, () => {
					userFilter.extraFetch = fetch;
					let f = { ...userFilter };
					props.state.setUserFilters(x => x.map(y => y === userFilter ? f : y));
					props.state.setSelected(f, props.state.selected[f.name]||[]);
				});
				return;
			} else if (d === 1) {
				let f = { ...userFilter };
				f.label = prompt("Filter name", f.label) || f.label;
				props.state.setUserFilters(x => x.map(y => y === userFilter ? f : y));
				return;
			}
		}
		props.state.setSelected(userFilter, []);
		props.state.setUserFilters(x => x.filter(y => y !== userFilter));
	}
	const clearLookupFilter = async (e: React.MouseEvent, userFilter: IQuickFilter) => {
		props.state.setSelected(userFilter, []);
	}

	return <div className="quickFilterPanel">
		{filters.map((x, i) => {

			if (customize) {
				return <div {...dragEvents} data-kind="quickfilter" data-drag_index={i} key={x.name} className="customizeQuickFilter"
					onClick={configureClick}>{x.name}<i className="fa fa-gears" /></div>
			}

			if (x.permissions && !checkCommandPermissions(false, metadata, {}, x.permissions))
				return undefined;

			if (x.name === "AddUserFilter")
				return <AddUserFilter key="addUserFilter" objectName={list.query.entity.name} state={props.state} />
			
			const meta = metadata.objects.find(y => y.logicalName === x.objectName);
			if (!meta) throw Error("Object not found:" + x.objectName);

			let propMeta = meta.properties.find(y => y.logicalName === x.propertyName);
			if (!propMeta) {
				x.optionsFormat = "radio";
				if (x.type === "custom") {
					propMeta = { logicalName: "__af__", displayName: "Advanced Find", type: 0, flags: 0, options: ["1"], richOptions: [{ value: "1", label: x.label }] };
				} else {
					throw Error("Field not found: " + x.objectName + "." + x.propertyName);
				}
			}

			const filterSelection = props.state.selected[x.name];
			const Element = getFilterElement(x);
			let filterElement = <Element propMeta={propMeta} key={x.name} filter={x} onChange={props.state.setSelected} selected={filterSelection} />

			let removeAction: any = undefined;

			if (i >= (list.quickFilters ? list.quickFilters.length : 0)) {
				removeAction = removeUserFilter;
			} else if (x.type === "lookup" && filterSelection?.length) {
				removeAction = clearLookupFilter;
			}

			if (removeAction) {
				filterElement = <div style={{ display: "flex" }}>
					<i className="fa fa-close quickFilterDeleteUser" 
						onClick={(e)=>removeAction(e, x)} />
					{filterElement}
				</div>
			}
			return filterElement;
		})}
		{customize &&
			<div {...de} className="formItemCustomizeAdd" style={{fontSize:"17px", height:"2em"}} data-kind="quickfilter" data-drag_index={filters.length}
				onClick={configureClick}><span><i className="fa fa-plus" />Quick Filter</span></div>}
	</div>
}

export const useQuickFilter = (list: IList): [IQuickFilterState, Fetch|undefined] => {

	const [userFilters, setUserFilters] = useSessionState("QF_user" + list.name, [] as IQuickFilter[], [list.name]);
	const filters = (list.quickFilters || []).concat(userFilters);

	const initialOptions = useMemo(() => {
		//const init: (string[] | undefined)[] = [];
		const init: { [key: string]: string[]|undefined} = {}
		if (list.quickFilters) {
			for (let i = 0; i < list.quickFilters.length; i++) {
				const filter = list.quickFilters[i];
				if (filter.mustSelect) {
					//init.push([filter.defaultOption || filter.initialOption || ""]);
					init[filter.name] = [filter.defaultOption || filter.initialOption || ""]
				} else if (filter.initialOption) {
					//init.push([filter.initialOption]);
					init[filter.name] = [filter.initialOption];
				} else {
					init[filter.name] = undefined;
				}
			}
		}
		return init;
	}, [list.quickFilters]);
	
	// fixme: we should have a dictionary insted of array. 
	// Changing the order of filters will break filter - value assoc
	const [selected, setSelected] = useSessionState("QF_" + list.name, initialOptions, [list.quickFilters]);
	const onFilterChanged = useCallback((changed: IQuickFilter, options: string[]) => {
		//const i = filters.indexOf(changed);
		setSelected(selected => {
			let newSelected = { ...selected, [changed.name]: options }
			// let newSelected = [...selected];
			// newSelected[i] = options;
			return newSelected;
		});
		
	}, [list.quickFilters]); 

	const q = useMemo(() => {
		let queryFilters: FetchFilter[] = [];
		let extraFetch: Fetch[] = [];
		let mandatoryFilters: any = {};
		for (let i = 0; i < filters.length; i++) {
			const filter = filters[i];
			const options = selected[filter.name];

			let hasValue = false;
			if (options && options.length > 0) {
				hasValue = true;

				if (filter.type === "date") {
					let s, e;
					switch (options[0]) {
						case "today": s = getToday(); e = addTime(s, 1, "days"); break;
						case "tomorrow": s = addTime(getToday(), 1, "days"); e = addTime(s, 1, "days"); break;
						case "yesterday": s = addTime(getToday(), -1, "days"); e = addTime(s, 1, "days"); break;
						case "this-week": s = getStartOfWeek(); e = addTime(s, 7, "days"); break;
						case "next-week": s = addTime(getStartOfWeek(), 1, "weeks"); e = addTime(s, 7, "days"); break;
						case "prev-week": s = addTime(getStartOfWeek(), -1, "weeks"); e = addTime(s, 7, "days"); break;
						case "this-month": s = getStartOfMonth(); e = addTime(s, 1, "months"); break;
						case "next-month": s = addTime(getStartOfMonth(), 1, "months"); e = addTime(s, 1, "months"); break;
						case "prev-month": s = addTime(getStartOfMonth(), -1, "months"); e = addTime(s, 1, "months"); break;
						case "this-year": s = getStartOfYear(); e = addTime(s, 1, "years"); break;
						case "next-year": s = addTime(getStartOfYear(), 1, "years"); e = addTime(s, 1, "years"); break;
						case "prev-year": s = addTime(getStartOfYear(), -1, "years"); e = addTime(s, 1, "years"); break;
					}
					if (s && e) {
						queryFilters.push({
							type: "and",
							conditions: [{ attribute: filter.propertyName, operator: "ge", value: s.toISOString() },
							{ attribute: filter.propertyName, operator: "lt", value: e.toISOString() }
							],
						});
					}
				} else if (filter.type === "day") {
					if (options[0]) {
						const s = new Date(options[0]);
						if (s.getFullYear() > 1800 && s.getFullYear() < 2200) {
							const e = new Date(s);
							e.setDate(e.getDate() + 1);
							queryFilters.push({
								type: "and",
								conditions: [{ attribute: filter.propertyName, operator: "ge", value: s.toISOString() },
								{ attribute: filter.propertyName, operator: "lt", value: e.toISOString() }
								],
							});
						} else {
							hasValue = false;
						}
					} else {
						hasValue = false;
					}
				} else if (filter.type === "lookup") {
					const filterValue = JSON.parse(options[0]).id;
					if (filter.customFetch) {
						const ef = JSON.parse(JSON.stringify(filter.customFetch))
						applyMacros(ef.entity, { [filter.propertyName]: filterValue });
						extraFetch.push(ef);
					} else {
						queryFilters.push({
							type: "and",
							conditions: [{ attribute: filter.propertyName, operator: "eq", value: filterValue }],
						});
					}
				} else if (filter.type === "owner") {
					queryFilters.push({
						type: "and",
						conditions: [{ attribute: filter.propertyName, operator: "eq-userid" }],
					});
				} else if (filter.type === "daterange") {
					if (options[0] || options[1]) {
						let ff = { conditions: [] as any[] };
						if (options[0])
							ff.conditions.push({ attribute: filter.propertyName, operator: "ge", value: options[0] });
						if (options[1]) {
							let end = new Date(options[1]);
							end = new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1);
							ff.conditions.push({ attribute: filter.propertyName, operator: "lt", value: end.toISOString() });
						}
						queryFilters.push(ff);
					}
				} else if (filter.type === "numericrange") {
					if (options[0] || options[1]) {
						let ff = { conditions: [] as any[] };
						if (options[0])
							ff.conditions.push({ attribute: filter.propertyName, operator: "ge", value: options[0] });
						if (options[1]) {
							ff.conditions.push({ attribute: filter.propertyName, operator: "le", value: options[1] });
						}
						queryFilters.push(ff);
					}
				} else if (filter.type === "text" || filter.type === "number") {
					let searchText = options[0];
					if (searchText && searchText.length >= (filter.minimumLength || 0)) {
						let operator = "eq"; // put this on the filter itself
						if (filter.type === "text") {
							operator = "like";
							searchText = "%" + searchText + "%";
						}
						queryFilters.push({
							type: "and",
							conditions: [{ attribute: filter.propertyName, operator: operator as any, value: searchText }],
						});
					} else {
						hasValue = false;
					}
				} else if (filter.type === "custom") {
					if (filter.extraFetch)
						extraFetch.push(filter.extraFetch);
				} else {
					queryFilters.push({
						type: "or",
						conditions: options.map(x => ({ attribute: filter.propertyName, operator: "like", value: x }))
					});
				}
			}

			if (filter.mandatoryGroup && (hasValue || !mandatoryFilters[filter.mandatoryGroup])) {
				mandatoryFilters[filter.mandatoryGroup] = hasValue;
			} 
		}

		if (Object.values(mandatoryFilters).includes(false)) {
			// no value and mandatory - create a query that will never return results.
			queryFilters.push({
				conditions: [{ attribute: "id", operator: "null" }]
			});
		}

		let q: Fetch = undefined as any;
		if (queryFilters.length > 0) {
			q = {
				entity: {
					name: list.query.entity.name,
					filter: { filters: queryFilters },
				}
			} as Fetch;
		}
		if (extraFetch.length > 0) {
			if (!q) q = { entity: { name: list.query.entity.name } };
			for (const mf of extraFetch) {
				mergeFetch(q, mf);
			}
		}
		return q;
	}, [selected]);

	return [{ selected, setSelected: onFilterChanged, userFilters, setUserFilters }, q];
}

const getToday = () => {
	const d = new Date();
	return new Date(d.getFullYear(), d.getMonth(), d.getDate());
}

const getStartOfWeek = () => {
	const d = new Date();
	const x = (d.getDay() + 6) % 7; // Sunday - Saturday => Monday - Sunday
	return new Date(d.getFullYear(), d.getMonth(), d.getDate() - x);
}
const getStartOfMonth = () => {
	const d = new Date();
	return new Date(d.getFullYear(), d.getMonth(), 1);
}
const getStartOfYear = () => {
	const d = new Date();
	return new Date(d.getFullYear(), 0, 1);
}

const addTime = (d: Date, time: number, op: "seconds" | "hours" | "days" | "weeks" | "months" | "years") => {
	const maxMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	const t = d.valueOf();
	let m;
	switch (op) {
		case "seconds": return new Date(t + time * 1000);
		case "hours": return new Date(t + time * 3600 * 1000);
		case "days": return new Date(t + time * 86400 * 1000);
		case "weeks": return new Date(t + time * 7 * 86400 * 1000);
		case "months":
			m = (d.getMonth() + time + 12) % 12;
			return new Date(d.getFullYear(), d.getMonth() + time, Math.min(d.getDate(), maxMonth[m]));
		case "years":
			//m = (d.getMonth() + 12) % 12;
			return new Date(d.getFullYear() + time, d.getMonth(), d.getDate());
		default:
			return d;
	}
}