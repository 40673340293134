import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconButton } from "../components/IconButton";
import { Loading } from "../components/Loading";
import { LoginWithGoogle, tryGetOrgName } from "./LoginPanel";

export const SignupPanel = () => {

	const initOrg = tryGetOrgName();

	const [login, setLogin] = useState({ person: "", email: "", password: "", password2: "", organization: initOrg || "" });
	const [isSending, setSending] = useState(false);
	const [status, setStatus] = useState({ msg: "", err: false });
	const navigate = useNavigate();

	const handleSubmit = async (e?: any) => {
		if (e)
			e.preventDefault();
	
		const { email, password } = login;
		if (!email || !password) return;

		setSending(true);
		const data = new URLSearchParams();
		data.append("organization", login.organization);
		data.append("person", login.person);
		data.append("username", email);
		data.append("password", password);
		const resp = await fetch("/api/signup", {
			method: "POST",
			body: data
		});

		try {
			const text = await resp.text();
			if (!resp.ok) {
				setStatus({ msg: "Invalid Username or password", err: true });
			}
			else {
				setStatus({ msg: "Welcome back!", err: false });
				setTimeout(() => navigate("/", { replace: true }), 700);
			}
		}
		catch {
			//resp.ok
		}
		setSending(false);
	}
	let pwdMatch = true;
	if (login.password && login.password2 && login.password !== login.password2) {
		pwdMatch = false;
	}

	const onLoginWithGoogle = (e: any) => {
		// if (window.location.hostname === "localhost") {
		// 	window.location.href = "http://localhost:3001/auth/google/signup?redirectUrl=" + encodeURIComponent("http://localhost:3000");
		// } else {
		// 	window.location.pathname = "/auth/google/signup";
		// }
		if (e)
			e.preventDefault();
		
		const authProvider = "google";
		const organization = login.organization;
		let redirect = "/";
		let origin = window.location.origin;
		const originParam = "&origin=" + encodeURIComponent(origin);
		if (window.location.hostname === "localhost") {
			redirect = origin + redirect;
			origin = "http://localhost:3001"; // proxy will try to handle localhost:3000 and just do nop.
		}
		const url = origin + "/auth/" + authProvider + "/signup/" + organization + "?redirectUrl=" + encodeURIComponent(redirect) + originParam;
		window.location.assign(url);
	}

	return (<form><fieldset className="loginForm" disabled={isSending}>
		<div className="formItem" style={{display:initOrg?"none" : "flex"}}>
			<label className="formLabel" htmlFor="org">Organization:</label>
			<input id="org" type="text" value={login.organization} onChange={e => setLogin({ ...login, organization: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="email">Name:</label>
			<input id="email" type="email" value={login.person} onChange={e => setLogin({ ...login, person: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="email">Email:</label>
			<input id="email" type="email" value={login.email} onChange={e => setLogin({ ...login, email: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Password:</label>
			<input id="password" type="password" value={login.password} onChange={e => setLogin({ ...login, password: e.target.value })} />
		</div>
		<div className="formItem">
			<label className="formLabel" htmlFor="password">Confirm&nbsp;Password:</label>
			<input id="password" type="password" value={login.password2} onChange={e => setLogin({ ...login, password2: e.target.value })} />
		</div>
		<IconButton icon="" style={{width:"100%"}} disabled={!login.person || !login.email || !login.password || !pwdMatch || !login.organization} onClick={handleSubmit} label="Sign Up"/>
		
		<LoginWithGoogle label="Sign-up with Google" onClick={onLoginWithGoogle} disabled={!login.organization} />
		{isSending && <Loading />}
		{status.msg && <div style={{ color: status.err ? "red" : "green" }}>{status.msg}</div>}
		{!pwdMatch && <div style={{ color: "red" }}>Passwords do not match.</div>}
	</fieldset></form>);
}
