import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { IMetadata, IMetaObject, IMetaProperty, IRichOption, MetaPropertyFlagMax, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { ICommand, IListColumn } from "../AppSchema";
import { IconButton } from "../components/IconButton";
import { Loading } from "../components/Loading";
import SimpleGrid from "../components/SimpleGrid";
import { TitleBox } from "../components/TitleBox";
import { IModalContext, IModalParent, ModalContext } from "../modal/Modal";
import { download } from "../services/download";
import { fetchJson } from "../services/fetchJson";


const newProperty = { logicalName: "", type: MetaPropertyType.String, flags: 0, max: "", targets: [], options: [] };
const defaultNewObject = { logicalName: "", primaryKeyName: "id", primaryFieldName: "name", properties: [], isNew: true };

const arrayToString = (arr?: any[]) => {
	if (!arr)
		return "";
	return arr.join(", ");
}
const stringToArray = (s: string) => {
	if (!s) return undefined;
	return s.split(",").map(x => x.trim());
}

const ValidateAndSaveMetadataDialog = (props: {sql:string, newMeta: IMetadata, onSave: ()=>Promise<void>, commandSite: IModalParent}) => {
	const cmds = useMemo(() => [{ name: "CmdImport", label: "Save", icon: "save" },
		{ name: "CmdClose", label: "Close", icon: "close" }],[]);
	
	const [loading, setLoading] = useState(false);
	
	const onCommand = async (cmd: ICommand) => {
		if (cmd.name === "CmdImport") {
			setLoading(true);
			await props.onSave();
			//setLoading(false);
			props.commandSite?.closeModal();
		}
		else if (cmd.name === "CmdClose") {
			props.commandSite?.closeModal();
		}
	};

	const text = props.sql ? props.sql.split('\n').map(x => {
		let color = "black";
		if (x.indexOf("CREATE") >= 0 || x.indexOf("ADD") >= 0)
			color = "green";
		else if (x.indexOf("DROP") >= 0 || x.indexOf("DELETE") >= 0)
			color = "red";
		return <div style={{ color: color }}>{x}</div>
	}) : <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>No Database Changes</div>

	return (<div className="objectListContainer" style={{ flex: "1 1 auto" }}>
		<TitleBox title="Review Changes and Save" commands={cmds} onCommand={onCommand} />
		{loading && <Loading/>}
		<div style={{flex: "1 1 auto", overflow: "scroll"}}>
			{text}
		</div>
	</div>)
}

const makeCleanMeta = (meta: IMetadata): IMetadata => {
	const sendMeta = meta.objects.filter(o => !o.isDeleted).map(o => {
		const { isDeleted, isNew, isModified, ...newObj } = o;
		newObj.properties = newObj.properties.filter(f => !f.isDeleted).map(f => {
			const { isDeleted, isNew, isModified, ...newProp } = f;
			return newProp;
		});
		return newObj;
	});
	return { objects: sendMeta, languages: meta.languages };
}

export const updateMetadata = async (modalContext: IModalContext, meta: IMetadata) => {
	const sendMeta = makeCleanMeta(meta);
	return new Promise(async (res, rej) => {
		try {
			const sql = await fetchJson("/api/checkmetadata", sendMeta, false);

			const executeUpdate = async () => {
				try {
					const text = await fetchJson("/api/updatemetadata", sendMeta, false);
				}
				catch (err: any) {
					alert((err && err.message) || "Metadata Error");
				}
				res(0);
			};

			modalContext.showModal({
				id: "importDialog",
				showTitle: false,
				body: (props) => (<ValidateAndSaveMetadataDialog {...props} />),
				bodyProps: { sql: sql, newMeta: sendMeta, onSave: executeUpdate },
			});
		}
		catch (err: any) {
			alert((err && err.message) || "Metadata Error");
		}
	});
}

export const fetchServerMetadata = async () => {
	const objs = await fetchJson("/api/getmetadata", undefined) as IMetadata;
	for (let o of objs.objects) {
		o.properties.sort((a, b) => a.logicalName.localeCompare(b.logicalName));
	}
	return objs;
}

export const CustomizeMetadata = () => {

	const [isLoading, setLoading] = useState(true);
	const [meta, setMeta] = useState<IMetadata>({ objects: [] });
	const [selectedObj, setSelectedObj] = useState<IMetaObject>();
	const [newField, setNewField] = useState<IMetaProperty>({ ...newProperty });
	const [newObject, setNewObject] = useState<IMetaObject>({ ...defaultNewObject });
	const modalContext = useContext(ModalContext);
  
	const setMetaObjects = (metaObjects: IMetaObject[]) => {
		setMeta(x => {
			if (x) {
				return { ...x, objects: metaObjects };
			}
			return { objects: metaObjects};
		})
	}

	const fetchMetadata = async () => {
		const objs = await fetchServerMetadata();
		setMeta(objs);
		setLoading(false);
		return objs;
	};

	const [checkedObjs, __setCheckedObjs] = useState({} as { [name: string]: boolean });
	const setCheckedObjs = (e: React.ChangeEvent) => {
		const logicalName = (e.target as HTMLInputElement)?.name;
		__setCheckedObjs(x => {
			return { ...x, [logicalName]: !x[logicalName] }
		});
	}
  
	useEffect(() => {
		fetchMetadata();
	}, []);
  
	const onAddObject = () => {
		if (!newObject) return;
  
		if (meta.objects.find(x => x.logicalName === newObject.logicalName)) {
			alert("Object already exists");
			return;
		}
  
		//const newObj: IMetaObject = { logicalName: newObject, properties: [], primaryFieldName:"name", primaryKeyName:"id", flags:0, isNew: true };
		const newMeta = meta.objects.concat(newObject).sort((a, b) => a.logicalName.localeCompare(b.logicalName));
		setMetaObjects(newMeta);
		setSelectedObj(newObject);
		setNewObject({ ...defaultNewObject });
	}
  
	const onDeleteObject = (o: IMetaObject) => {
		const selObjName = selectedObj?.logicalName;
		const newMeta = o.isNew ? meta.objects.filter(x => x !== o) : meta.objects.map(x => {
			if (x === o) {
				const newX: IMetaObject = { ...x, isDeleted: !x.isDeleted };
				return newX;
			}
			return x;
		});
		setMetaObjects(newMeta);
		setSelectedObj(newMeta.find(x => x.logicalName === selObjName));
	}

	const onChangeObject = (oldObj: IMetaObject, newObj: IMetaObject) => {
		if (oldObj === newObject) {
			setNewObject(newObj);
		} else {
			const newMeta = meta.objects.map(x => x === oldObj ? newObj : x);
			setMetaObjects(newMeta);
			setSelectedObj(newObj);
		}
	}
  
	const onAddField = () => {
		if (!newField.logicalName) {
			return;
		}
		if (selectedObj?.properties.find(x => x.logicalName === newField.logicalName)) {
			alert("Field already exists");
			return;
		}
  
		const selObjName = selectedObj?.logicalName;
		const newMeta = meta.objects.map(x => {
			if (x === selectedObj) {
				const newProp = { ...newField, isNew: true };
				const newX: IMetaObject = { ...x };
				newX.properties = newX.properties.concat(newProp).sort((a, b) => a.logicalName.localeCompare(b.logicalName));
				return newX;
			}
			return x;
		});
		setNewField({ ...newField });
		setMetaObjects(newMeta);
		setSelectedObj(newMeta.find(x => x.logicalName === selObjName));
	}
  
	const onDeleteField = (f: IMetaProperty) => {
		const selObjName = selectedObj?.logicalName;
		const newMeta = meta.objects.map(x => {
			if (x === selectedObj) {
				const newX: IMetaObject = { ...x };
				if (f.isNew) {
					newX.properties = newX.properties.filter(y => y !== f);
				} else {
					const newProp: IMetaProperty = { ...f };
					newProp.isDeleted = !f.isDeleted;
					newX.properties = newX.properties.map(y => y !== f ? y : newProp).sort((a, b) => a.logicalName.localeCompare(b.logicalName));
				}
				return newX;
			}
			return x;
		});
		setMetaObjects(newMeta);
		setSelectedObj(newMeta.find(x => x.logicalName === selObjName));
	};

	const onChangeField = (oldField: IMetaProperty, ff: IMetaProperty) => {
		if (oldField === newField) {
			setNewField(ff);
		} else {
			const selObjName = selectedObj?.logicalName;
			const newMeta = meta.objects.map(x => {
				if (x === selectedObj) {
					const newX: IMetaObject = { ...x };
					newX.properties = newX.properties.map(y => y === oldField ? ff : y).sort((a, b) => a.logicalName.localeCompare(b.logicalName));
					return newX;
				}
				return x;
			});
			setMetaObjects(newMeta);
			setSelectedObj(newMeta.find(x => x.logicalName === selObjName));
		}
	}

	const onSave = async () => {
		const selObjName = selectedObj?.logicalName;
		await updateMetadata(modalContext, meta);
		
		setLoading(true);
		const newMeta = await fetchMetadata();
		if (selObjName) {
			setSelectedObj(newMeta.objects.find(x => x.logicalName === selObjName));
		}
	}

	const onExport = useCallback(() => {
		const sendMeta = makeCleanMeta(meta);
		//Object.entries(checkedObjs).filter(x => x[1]).map(x => x[0]);
		const partial = sendMeta.objects.filter(x => checkedObjs[x.logicalName]);
		if (partial.length > 0) {
			sendMeta.objects = partial;
			sendMeta.partial = true;
		}
		const json = JSON.stringify(sendMeta, undefined, 2);
		download(json, "metadata.json", "text/json");
	}, [meta, checkedObjs]);

	const onExportCSV = useCallback(() => {
		const sendMeta = makeCleanMeta(meta);
		let sb = "Type;Name;Label;Plural\n";
		for (const e of sendMeta.objects) {
			sb += `OBJECT;${e.logicalName};${e.displayName||""};${e.displayNamePlural||""};\n`;
			for (const f of e.properties) {
				sb += `${MetaPropertyType[f.type]};${f.logicalName};${f.displayName||""}\n`;
			}
			sb += "\n";
		}
		download("\ufeff" + sb, "metadata.csv", "text/csv");
	}, [meta]);

	const uploadRef = useRef<HTMLInputElement>(null);
	const onImportClick = useCallback(() => {
		uploadRef.current?.click();
	}, [uploadRef]);

	const onUploadFile = useCallback((e: React.FormEvent<HTMLInputElement>) => {
		const files = (e.target as HTMLInputElement).files;
		if (files && files.length > 0) {
			const handleFile = async () => {
				try {
					const jsonText = await files[0].text();
					const newMeta = JSON.parse(jsonText) as IMetadata;
					if (!newMeta.objects || !newMeta.objects.length)
						throw Error("Invalid metadata file");
					
					const selObjName = selectedObj?.logicalName;

					if ((newMeta as any)["partial"] || !newMeta.objects.find(x=>x.logicalName === "systemuser")) {
						setMeta(old => {
							const tmp = { ...old, objects: [...old.objects] };
							for (const newObj of newMeta.objects) {
								let i = tmp.objects.findIndex(x => x.logicalName === newObj.logicalName);
								if (i >= 0)
									tmp.objects[i] = newObj;
								else
									tmp.objects.push({ ...newObj, isNew: true });
							}
							tmp.objects.sort((a, b) => a.logicalName.localeCompare(b.logicalName));
							return tmp;
						});
					} else {
						setMeta(newMeta);
					}
					//setMeta(oldMeta => ({...oldMeta, ...newMeta}));
					setSelectedObj(newMeta.objects.find(x => x.logicalName === selObjName));
				}
				catch (e) {
					alert(e);
				}
			};
			handleFile();
		}
	}, [setMeta, setSelectedObj]);
  
	const propFormatter = (prop: IMetaProperty, propName: string) => {
		const isNew = prop === newField;
		switch (propName) {
			case "logicalName":
				if (!isNew) {
					const style = { backgroundColor: "" };
					if (prop.isDeleted) style.backgroundColor = "#fee";
					if (prop.isNew) style.backgroundColor = "#efe";
					return (<div style={style}>{prop.logicalName}</div>)
				}
				else {
					return (<input placeholder="New Property" type="text" value={prop.logicalName||""} onChange={e => onChangeField(prop, { ...prop, logicalName: e.target.value })} />);
				}
			case "displayName": return (<input placeholder="Display Name" type="text" value={prop.displayName||""} onChange={e => onChangeField(prop, { ...prop, displayName: e.target.value })} />);
			case "max": return (<input type="number" style={{width:"50px"}} value={prop.max||""} onChange={e => onChangeField(prop, { ...prop, max: e.target.value })} />);
			case "flags": {
				let options = [];
				let selOpts = [];
				for (let i = 0; i < MetaPropertyFlagMax; i++) {
					const op = { label: MetaPropertyFlags[1 << i], value: 1 << i };
					options.push(op);
					if (prop.flags & op.value)
						selOpts.push(op);
				}
				const setSelectedOpts = (newSel: any[]) => {
					let newFlags = 0;
					for (let o of newSel) {
						newFlags |= o.value;
					}
					onChangeField(prop, { ...prop, flags: newFlags });
				}
		   
				return (<MultiSelect options={options} labelledBy="" className="multiSelect"
					value={selOpts} hasSelectAll={false} disableSearch={true}
					onChange={setSelectedOpts} />)
			}
			case "targets": return (<input type="text" value={arrayToString(prop.targets)} onChange={e => onChangeField(prop, { ...prop, targets: stringToArray(e.target.value) })} />);
			case "options": return (<input type="text" value={arrayToString(prop.options)} onChange={e => onChangeField(prop, { ...prop, options: stringToArray(e.target.value) })} />);
			case "precision": return (<input style={{width:"50px"}} type="number" value={prop.precision||""} onChange={e => onChangeField(prop, { ...prop, precision: e.target.value })} />);
			case "default": return (<input type="text" value={prop.defaultValue||""} onChange={e => onChangeField(prop, { ...prop, defaultValue: e.target.value })} />);
			//case "isRequired": return (<input type="text" value={prop.defaultValue||""} onChange={e => onChangeField(prop, { ...prop, defaultValue: e.target.value })} />);	

			case "type": return isNew ? (<select value={prop.type} onChange={e => onChangeField(prop, { ...prop, type: +e.target.value })}>
				{[...Array(10)].map((x, i) => {
					const z = MetaPropertyType[i];
					return (<option key={z} value={i}>{z}</option>);
				})}
			</select>) : MetaPropertyType[prop.type];
			case "actions": return isNew ? (<button onClick={onAddField}>Add</button>) :
				(<div>
					<button onClick={e => {
						showRichOptionsDialog(modalContext, prop, newField => onChangeField(prop, newField));
					}}>Opts</button>
					<button onClick={e => onDeleteField(prop)}>{prop.isDeleted ? "Undo" : "Del"}</button>
					</div>);
	
			default: return "";
		}
	}
	
	const hdr = ["logicalName", "displayName", "type", "flags", "max", "targets", "options", "default", "precision", "actions"];

	const objectFields = ["selected", "logicalName", "displayName", "displayNamePlural", "type", "actions"];
	const objectColumns: IListColumn[] = objectFields.map(x => ({ attribute: x, label: x }));
	objectColumns[0].width = "40px";
	objectColumns[0].label = "S";
  
	return isLoading ? (<div>Loading...</div>) : (
		<div className="customizeMain">
			<div className="customizeEntityPanel">
				<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "auto 5px" }}><h1>Objects</h1>
					<div style={{ flex: "1 1 auto" }}></div>
					<IconButton icon={'file-import'} label="Import" onClick={onImportClick} />
					<IconButton icon={'file-export'} label="Export" onClick={onExport} />
					<IconButton icon={'file-csv'} label="CSV" onClick={onExportCSV} />
					<IconButton icon={'save'} onClick={onSave} />
					<input ref={uploadRef} hidden={true} type="file" onChange={e => e.target.value = null as any} 
          				onInput={onUploadFile} accept="text/json" />
				</div>
				<SimpleGrid keyFunc={x => x === newObject ? "__new__" : x.logicalName} className="customizeEntityList" fields={objectFields}
					listColumns={objectColumns}
					rows={[newObject].concat(meta.objects)} noEdit={true} formatter={(x: IMetaObject, fname) => {
			
						switch (fname) {
							case "logicalName": {
								if (x === newObject)
									return (<input placeholder="New Object" type="text" value={x.logicalName} onChange={e => onChangeObject(x, { ...x, logicalName: e.target.value })} />);

								const style = { backgroundColor: "", borderLeft: "" };
								if (x.isDeleted) style.backgroundColor = "#fee";
								if (x.isNew) style.backgroundColor = "#efe";
								if (x === selectedObj) {
									style.borderLeft = "5px solid navy";
								}
								return (<div style={style} onClick={e => setSelectedObj(x)} >{x.logicalName}</div>);
							}
							case "actions":
								if (x === newObject)
									return (<button onClick={onAddObject}>Add</button>);
								return (<button onClick={e => onDeleteObject(x)}>{x.isDeleted ? "Undo" : "Delete"}</button>);
							case "type":
								if (x === newObject)
									return (<select value={x.type || "normal"} onChange={e => onChangeObject(x, { ...x, type: e.target.value as any })} ><option value="normal">Normal</option><option value="many">Many to Many</option></select>);
								return <div>{x.type || "normal"}</div>
							case "displayName":
								return (<input placeholder="Display Name" type="text" value={x.displayName || ""} onChange={e => onChangeObject(x, { ...x, displayName: e.target.value })} />);
							case "displayNamePlural":
								return (<input placeholder="Display Plural" type="text" value={x.displayNamePlural || ""} onChange={e => onChangeObject(x, { ...x, displayNamePlural: e.target.value })} />);
							case "selected":
								return <input type="checkbox" name={x.logicalName} checked={!!checkedObjs[x.logicalName]} onChange={setCheckedObjs} />
						}
						return "";
					}} />
			</div>
			{selectedObj && (<div className="customizePropertyPanel">
				<div><h1>Properties [{selectedObj.logicalName}]</h1></div>
				<SimpleGrid fields={hdr} rows={[newField].concat(selectedObj.properties)} noEdit={true} formatter={propFormatter}
				keyFunc={x=>x===newField?"__newfield__":x.logicalName}/>
			</div>)
			}
		</div>
	);
}

export const showRichOptionsDialog = (modal: IModalContext, prop: IMetaProperty, onChange: (newProp: IMetaProperty) => void) => {

	const Editor = (props: { value: string, onSave: (value: string) => void, commandSite: IModalParent }) => {
		const cmds = useMemo(() => [{ name: "CmdSave", label: "Save", icon: "save" },
		{ name: "CmdClose", label: "Close", icon: "close" }], []);
		
		const onCommand = async (cmd: ICommand) => {
			if (cmd.name === "CmdSave") {
				props.onSave(value);
				//setLoading(false);
			}
		
			props.commandSite?.closeModal();
		};

		const [value, setValue] = useState(props.value);

		return (<div className="objectListContainer" style={{ flex: "1 1 auto", alignSelf:"stretch" }}>
			<TitleBox title="Extended Options" commands={cmds} onCommand={onCommand} />
			<div style={{fontWeight:"bold"}}>VALUE;LABEL;COLOR;ICON;DEPENDENT_FIELD;DEPENDENT_FIELD_VALUE</div>
			<div style={{ flex: "1 1 auto" }}>
				<textarea style={{ width: "100%", height: "100%", font:"inherit" }}  value={value} onChange={e => setValue(e.target.value)} />

			</div>
		</div>)
	};

	let sb = "";
	if (prop.richOptions) {
		for (const o of prop.richOptions) {
			sb += `${o.value};${o.label || ""};${o.color || ""};${o.icon || ""}`;
			if (o.depvalue)
				sb += `${o.depfield || ""};${o.depvalue || ""}`;
			sb += "\n";
		}
	} else if (prop.options) {
		for (const o of prop.options) {
			sb += `${o}\n`;
		}
	}

	modal.showModal({
		id: "dlgEditRichOptions",
		showTitle: false,
		body: (props) => (<Editor {...props} />),
		bodyProps: {
			value: sb, onSave: (str: string) => {
				const m = { ...prop };
				m.richOptions = undefined;
				if (str) {
					const r: any[] = [];
					for (const line of str.split('\n')) {
						if (!line) continue;
						const pp = line.split(';');
						if (!pp[0]) continue;
						const o: IRichOption = { value: pp[0], label: pp[1], color: pp[2], icon: pp[3] };
						if (pp[4])
							o.depfield = pp[4];
						if (pp[5])
							o.depvalue = pp[5];
						r.push(o);
					}
					if (r.length > 0) {
						m.options = r.map(x => x.value);
						m.richOptions = r;
					}
				}
				onChange(m)
		} },
	});
}