import { useCallback, useContext } from "react";
import { IDictionary, ILookupValue, IMetaObject, IMetaProperty, MetaPropertyFlags, MetaPropertyType } from "shared/schema";
import { IAppConfig, IListColumn } from "../AppSchema";
import { MetaContext } from "../AppState";
import { AppLink } from "../components/AppLink";
import { makeRichOptionSpan } from "../components/RichOptionSpan";
import { formatAge, formatDuration, formatMoneyValue, formatMoneyValueWithCurrency, formatNumericValue, formatNumericWith, propertyValueAsLink } from "../formatters";
import { getFileUrl } from "../services/getFileUrl";

export interface IFormatterProps {
	//query: Fetch;
	//fields?: string[];
	//columns?: string[];
	//noHeader?: boolean;
	listColumns: IListColumn[];
	onClick?: (obj: any) => void;
	disableLookupClick?: boolean;
	//onSortClick?: (index: number, field: string, column: string, asc: boolean) => void,
	onRenderCell?: (obj: any, field: string) => React.ReactNode,
	onSetRecord?: (newRecord: any) => void
}

const makeAppLink = (props: IFormatterProps, meta: IMetaObject, row: any, fieldName: string) => {
	const value = row[fieldName]
	let label: string;
	let objectName: string;
	let id: string;
	let disableLookupClick = props.disableLookupClick;

	if (fieldName === "name") {
		id = row.id;
		objectName = meta.logicalName;
		label = value as any;
		disableLookupClick = false;
	} else {
		const l = value as ILookupValue;
		id = l.id;
		objectName = l.name;
		label = l.label;
	}
	
	label = label || "(unknown)";
	if (disableLookupClick)
		return label;

	const listColumn = props.listColumns.find(x => x.attribute === fieldName);
	if (listColumn && listColumn.nolink)
		return label;

	let link = "/edit/" + objectName + "/" + id;
	if (listColumn && listColumn.formName) {
		link += "/" + listColumn.formName;
	}
	return <AppLink className="lookupLink" to={link}>{label}</AppLink>
}

export const useCellFormatter = (props: IFormatterProps, meta: IMetaObject, attrsMeta: IDictionary<IMetaProperty>) => {

	const metadata = useContext(MetaContext);

	const { disableLookupClick, onClick, onRenderCell } = props;

	const formatter = useCallback((row: any, fieldName: string, style: any) => {
		let value = row[fieldName];
		if (onRenderCell) {
			const result = onRenderCell(row, fieldName);
			if (result !== undefined)
				return result;
		}
		if (fieldName === "name") {
			if (onClick)
				return (<a href="#" className="lookupLink" onClick={e => {
					if (onClick)
						onClick(row);
					e.preventDefault();
				}}>{value || "(unknown)"}</a>);
			else {
				return makeAppLink(props, meta, row, fieldName);
			}
		}
		else {
			if (value && value["id"]) {
				return makeAppLink(props, meta, row, fieldName);
			}
			else {

				if (typeof value === "object") {
					console.log("Unknown composite row field:" + fieldName + " value:" + JSON.stringify(value));
					return "?";
				}

				const propMeta = attrsMeta[fieldName];
				if (propMeta) { 
					if (propMeta.type === MetaPropertyType.String && (propMeta.flags & (MetaPropertyFlags.Image|MetaPropertyFlags.File)) !== 0) {
						const isImage = value && (value.indexOf("image/") >= 0 || value.endsWith(".image"));
						value = getFileUrl(metadata, value);
						if (isImage) {
							value = <img src={value} className="objectListImageCell" />;
						} else {
							const imgClassName = (value ? "fa-file-invoice textColorAccent" : "fa-paw") + " fa objectListImageCell objectListImagePlaceHolder"
							value = <div className={imgClassName} />;
						}
					} else {
						value = simpleCellFormatterText(row, fieldName, propMeta, metadata, "rich");
					}
				}
			}
		}
		return value;
	}, [attrsMeta, meta, disableLookupClick, onClick, onRenderCell]);
	
	return formatter;
}

const getRichOption = (propMeta: IMetaProperty, value: any) => {
	if (propMeta.richOptions) {
		if (propMeta.type === MetaPropertyType.Boolean)
			return propMeta.richOptions[value ? 1 : 0];

		if ((value === "" || value === null || value === undefined) && propMeta.defaultValue)
			value = propMeta.defaultValue;
		const o = propMeta.richOptions.find(x => x.value == value);
		return o;
	}
}

export const simpleCellFormatter = (row: any, fieldName: string, propMeta: IMetaProperty, metadata?: IAppConfig) => {
	return propMeta &&  simpleCellFormatterText(row, fieldName, propMeta, metadata, "rich");
}

export const simpleCellFormatterText = (row: any, fieldName: string, propMeta: IMetaProperty, metadata?: IAppConfig, resultType?: "rich") => {
	let value = row[fieldName];
	
	if (value && value.id)
		return value.label || "(no name)";

	const ro = getRichOption(propMeta, value);
	if (ro)
		return resultType === "rich" ? makeRichOptionSpan(ro, true) : (ro.label || ro.value);

	if (value && propMeta.type === MetaPropertyType.DateTime) {
		value = value.indexOf("Z") < 0 ? value + "Z" : value;
		const dt = new Date(value);
		if (propMeta.flags && (propMeta.flags & MetaPropertyFlags.Age) !== 0)
			value = formatAge(dt);
		else if (propMeta.flags && (propMeta.flags & MetaPropertyFlags.DateOnly) !== 0)
			value = dt.toLocaleDateString();
		else
			value = dt.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' });
	}
	else if (propMeta.type === MetaPropertyType.Integer || propMeta.type === MetaPropertyType.Decimal) {
		if (propMeta.formatString)
			value = formatNumericWith(value, propMeta.formatString);
		else if ((propMeta.flags & MetaPropertyFlags.Duration) !== 0)
			value = formatDuration(value);
		else
			value = formatNumericValue(propMeta.precision, value);
	}
	else if (propMeta.type === MetaPropertyType.Money) {
		if (metadata)
			value = formatMoneyValue(metadata, row, value);
		else
			value = formatMoneyValueWithCurrency(undefined, value);
	}
	else if (resultType === "rich" && value && propMeta.type === MetaPropertyType.String && propMeta.flags) {
		const url = propertyValueAsLink(propMeta, value);
		if (url) {
			const icon = "fa-up-right-from-square";
			return <span>{value}<a className={"fa formItemAction " + icon} style={{ display: "inline", textDecoration: "none", background: "none", color: "navy", margin: "0 0 0 4px", padding: "0" }} href={url} target="_blank"></a></span>
		}
		//return undefined;
	}
	if (!resultType && value == null)
		return "";
	return value;
}