import { FetchCondition, Fetch } from "shared/fetch";
import { ILookupValue, MetaPropertyType } from "shared/schema";
import { IAppConfig } from "../AppSchema";
import { DataService } from "../service";

interface Instruction {
	type: "print" | "print_var" | "if" | "for" | "ifchild"
	arg?: any;
	blockEnd?: number;
}

const parsePrompt = (prompt: string) => {
	const instructions: Instruction[] = [];
	const blockInstr: Instruction[] = []

	let sb = "";

	const printBuffer = () => {
		if (sb)
			instructions.push({ type: "print", arg: sb });
		sb = ""
	}

	// PARSE
	for (let i = 0; i < prompt.length; i++) {
		let c = prompt[i]
		let c2 = prompt[i+1]
		if (c === "\\" && (c2 === "[" || c2 === "\\")) {
			sb += c2
			i++;
			continue;
		}

		if (c !== "[") {
			sb += c;
		} else {
			printBuffer();

			let ei = prompt.indexOf(']', i);
			if (ei < 0)
				throw Error("Control statement is not terminated with ] at pos:" + i + " text:" + prompt.substring(i, 20));
			const cmd = prompt.substring(i + 1, ei);
			let j = i;
			i = ei;

			if (cmd === "end") {
				if (blockInstr.length === 0)
					throw Error("Invalid 'end' statement at pos:" + j + " text:" + prompt.substring(j, 20));

				const prevBlock = blockInstr.pop()!
				prevBlock.blockEnd = instructions.length; // the next instruction
				continue;
			}
			
			if (cmd.startsWith("if ")) {
				instructions.push({type:"if", arg: cmd.substring(3)})
				blockInstr.push(instructions.at(-1)!);
				continue;
			}

			if (cmd.startsWith("ifchild ")) {
				instructions.push({type:"ifchild", arg: cmd.substring(8)})
				blockInstr.push(instructions.at(-1)!);
				continue;
			}

			if (cmd.startsWith("for ")) {
				instructions.push({type:"for", arg: cmd.substring(4)})
				blockInstr.push(instructions.at(-1)!);
				continue;
			}

			instructions.push({ type: "print_var", arg: cmd });
		}
	}
	printBuffer();

	if(blockInstr.length > 0)
		throw Error("Not enough end statements.")

	return instructions;
}

/**
 * This method executes the template statements withing in passed prompt.
 * Returns the prompt text where the template statements have been replaced by statement output.
 * Eg text not within [] is returned verbatim.
 * 
 * Statements
 * [property_name]
 * [lookup_property_name.parent_object_property_name]
 * 
 * [if property_name]
 * [end]
 * 
 * [for c in object_name]
 * [end]
 * 
 * [for c in object_name(parent_id=id and status='active')]
 * [end]
 */

interface ITextTemplateOptions {
	decorateVar?: boolean;
}

export const processTextTemplate = async (prompt: string, record: any, logicalName: string, metadata: IAppConfig, options?: ITextTemplateOptions) => {

	const blockScope: { vars: { [name: string]: any } }[] = [];
	blockScope.push({ vars: record });

	const lookupCache: { [name: string]: any } = {};

	const evalExpression = async (cmd: string) => {

		const p = cmd.split('.')

		for (let j = blockScope.length - 1; j >= 0; j--) {

			const formatResult = (r: any) => {
				if (r && r.id && r.name)
					r = r.label;
				if (!r) console.log("Variable undefined: " + cmd);
				return r || "";
			}

			const b = blockScope[j];
			if (b.vars.hasOwnProperty(p[0])) {
				const val = b.vars[p[0]];
				if (p.length === 1)
					return formatResult(val);

				if (val) {
					let j = 0;
					let refObject = b.vars;
					while (true) {
						let r = refObject[p[j]];
					
						if (j === p.length - 1)
							return formatResult(r);
		
						const ref = r as ILookupValue
						if (!r) {
							console.log("lookup target empty: " + p.slice(j).join('.'));
							return "";
						}
						if (ref.hasOwnProperty("id") && ref.hasOwnProperty("name") && ref.hasOwnProperty("label") &&
							Object.keys(ref).length === 3) {
							// if (!ref.id || !ref.name) {
							// 	console.log("ERROR: lookup target id missing: " + r + " at " + p.slice(j).join('.'));
							// 	return "";
							// }
							refObject = lookupCache[ref.name + "#" + ref.id];
							if (!refObject) {
								const meta = metadata.objects.find(x => x.logicalName === ref.name);
								refObject = await DataService.retrieveSingleById(meta!, ref.id);
								lookupCache[ref.name + "#" + ref.id] = refObject;
							}
						}
						else {
							refObject = r;
						}
						j++;
					}
				} else {
					console.log("Variable undefined: " + cmd);
				}
			}
		}

		console.log("Unknown variable: " + cmd);
		return "";
	}

	const parseConditions = async (conditions: string) => {
		const conds = conditions.split(" and ");
		const fc: FetchCondition[] = [];
		const opTable = [
			">=", "ge",
			"<=", "le",
			">", "gt",
			"<", "lt",
			"!=", "ne",
			"<>", "ne",
			"=", "eq",
			"like", "like",
			"on", "on"
		];
		for (const c of conds) {
			for (let u = 0; u < opTable.length - 1; u += 2) {
				let parts = c.split(opTable[u]);
				if (parts.length > 1) {
					let value = parts[1].trim();
					if (value[0] === "'" || value[0] === '"')
						value = value.substring(1, value.length - 1);
					else
						value = await evalExpression(value);
					fc.push({ attribute: parts[0].trim(), operator: opTable[u + 1] as any, value: value });
					break;
				}
			}
		}
		return fc;
	}

	const evalForExpression = async (name_in_cmd: string): Promise<[string, any[]]> => {
		// simplest case: name of object. Only for top level block
		let [varName, cmd] = name_in_cmd.split(" in ");
		cmd = cmd.trim();
		let x = cmd.indexOf("(");
		let conditions = "";
		let metaName = cmd;
		if (x > 0) {
			conditions = cmd.substring(x + 1, cmd.length - 1);
			metaName = cmd.substring(0, x);
		}
		const meta = metadata.objects.find(x => x.logicalName === metaName)!;
		if (!conditions) {
			const lookupName = meta.properties.find(x => x.type === MetaPropertyType.Lookup && x.targets && x.targets[0] === logicalName)!;
			conditions = lookupName.logicalName + "=id";
		}

		const fc = await parseConditions(conditions);
		
		const q: Fetch = {
			entity: {
				name: meta.logicalName,
				allattrs: true,
				filter: {
					conditions: fc
				},
				orders:[{attribute:"name"}]
			}
		}

		const arr = await DataService.retrieveMultiple(q);

		return [varName.trim(), arr];
	}

	const evalIfExpression = async (arg: string) => {

		if (arg[0] === "(") {
			let conditions = arg.substring(1, arg.length - 1);
			const fc = await parseConditions(conditions);
			for (const c of fc) {
				const lhs = await evalExpression(c.attribute);
				const rhs = c.value as any;
				let result = false;
				switch (c.operator) {
					case "eq": result = lhs == rhs; break;
					case "ne": result = lhs != rhs; break;
					case "gt": result = lhs > rhs; break;
					case "lt": result = lhs < rhs; break;
					case "like":
						if (rhs[0] === '%')
							result = lhs.indexOf(rhs.replaceAll("%", "")) >= 0;
						else
							result = lhs.startsWith(rhs.replaceAll("%", "")) >= 0;
						break;
					//case "on" result = 
				}
				if (!result)
					return false;
			}
			return true;
		} else {
			let negate = arg[0] === "!";
			if (negate)
				arg = arg.substring(1);
			let truth = await evalExpression(arg);
			if (negate)
				truth = !truth;
				return truth
		}
	}

	let sb = ""
	const instructions = parsePrompt(prompt);

	const executeInstr = async (start: number, end: number) => {

		for (let ip = start; ip < end; ip++) {
			const instr = instructions[ip];

			if (instr.type === "print") {
				sb += instr.arg;
				continue;
			}
			if (instr.type === "print_var") {
				let text = await evalExpression(instr.arg);
				if (options && options.decorateVar)
					text = "<b>" + text + "</b>";
				sb += text;
				continue;
			}
			if (instr.type === "if") {
				const truth = await evalIfExpression(instr.arg!);
				if (!truth) {
					ip = instr.blockEnd! - 1;
					continue;
				}
				// push block?
			}
			if (instr.type === "for") {
				// we could translate the for statement into jumps and ifs but lets keep it simple for now.
				const [varName, arr] = await evalForExpression(instr.arg);
				let index = 0;
				for (const el of arr) {
					blockScope.push({ vars: { [varName]: el, [varName + "_index"]: index } });
					await executeInstr(ip + 1, instr.blockEnd!)
					blockScope.pop();
					index++;
				}
				ip = instr.blockEnd! - 1;
				continue;
			}
			if (instr.type === "ifchild") {
				// we could translate the for statement into jumps and ifs but lets keep it simple for now.
				const [varName, arr] = await evalForExpression(instr.arg);
				let index = 0;
				if (arr.length > 0) {
					//blockScope.push({ vars: { [varName]: el, [varName + "_index"]: index } });
					await executeInstr(ip + 1, instr.blockEnd!)
					//blockScope.pop();
				}
				ip = instr.blockEnd! - 1;
				continue;
			}
		}
	}

	await executeInstr(0, instructions.length);
	return sb;
}