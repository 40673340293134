
export interface ICalendarEvent {
	id: string;
	name: string;

	customElement?: any; // generated in script
	start: string;
	end: string;
	url?: string;
	color?: string;
	icon?: string;
	canEdit?: boolean;

	columnIndex?: number;
	columnCount?: number;
}


export const DAY_IN_MILLIS = 86400 * 1000;

export const addDays = (d: Date, days: number) => {
	let dd = d.getTime();
	d = new Date(dd + days * DAY_IN_MILLIS);
	return d;
}
