import { ResponsiveBar } from "@nivo/bar";
import { TitleBox } from "../components/TitleBox";
import { IPanelProps } from "../objectForm/panels/PanelProps";
import { formatNumericValue } from "../formatters";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataService } from "../service";
import { makeFetch } from "shared/fetch";
import { ICommand } from "../AppSchema";
import { useNavigate } from "react-router-dom";


export const CommunityChart = (props: IPanelProps) => {
	const theme = {
		textColor: "var(--fore-color)",
	};

	const formatValue = (v: any) => formatNumericValue(0, v);
	const keys = ["y0"];

	const [data, setData] = useState([] as any);//[{ y0: 4, x_label: "a" }, { y0: 3, x_label: "b" }];
	const commands = useRef([{ name: "CmdEdit", id: "0" }]);

	useEffect(() => {
		const exe = async () => {
			const q = makeFetch("fi_community_eval", undefined, [["communityid", "eq", props.context.id]]);
			const records = await DataService.retrieveMultiple(q) as any[];
			if (records[0]) {
				let newData = [];
				const labels = ["housing", "salary", "usury", "work_avail", "education", "transportation", "health", "city"];
				const loc = ["Byvanie", "Mzda", "Vykoristovanie", "Práca", "Vzdelanie", "Doprava", "Zdravie", "Mesto"];
				//for (const l of labels) {
				for (let i = 0; i < labels.length; i++) {
					const l = labels[i];
					let y0 = records[0][l + "_eval"] || 0;
					newData.push({ y0: y0, x_label: loc[i] });
				}
				setData(newData);
				commands.current[0].id = records[0].id;
			}
		}
		exe();
	}, []);

	const navigate = useNavigate();
	const onCommand = useCallback((cmd: ICommand, e: any) => {
		let link = "/!/edit/fi_community_eval/" + commands.current[0].id;
		if (commands.current[0].id === "0") {
			const newParams = {
				communityid: { id: props.context.id, name: "fi_community", label: props.context.getRecord().name }
			};
			const pp = new URLSearchParams({ init: JSON.stringify(newParams) });
			link += "?" + pp.toString();
		}
		navigate(link);
	}, []); 

	return <div className="listContainer">
		<TitleBox title="Hodnotenie komunity" commands={commands.current} onCommand={onCommand} />
		<div style={{minHeight:"300px", height:"70vh"}}>
			<ResponsiveBar margin={{ top: 40, right: 20, bottom: 50, left: 60 }}
				data={data}
				theme={theme}
				// onClick={onChartBarClick}
				colors={()=>"#0071ebff"}
				label={v => formatValue ? formatValue(v.value) : v.formattedValue}
				axisLeft={{
					"tickValues": 4,
					format: formatValue,
				}}
				valueScale={{ 
					type: 'linear',
					min: 0,
					max: 5,
					clamp: true
				  }}
				axisBottom={{
					//   legend: 'day',
					//   legendOffset: -12,
					"tickSize": 5,
					"tickPadding": 5,
					"tickRotation": 15
				}}
				groupMode="grouped"
				keys={keys}
				indexBy="x_label"
			//legends={legends}
			//tooltipLabel={(v:any)=>props.getSeriesLabel(v.id, true)}
					
			/>
		</div>
	</div>
}