"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.makeFetch = exports.mergeFetch = exports.applyMacros = exports.FetchFilter = void 0;
class FetchFilter {
}
exports.FetchFilter = FetchFilter;
const applyMacros = (entity, rec) => {
    if (entity.filter)
        applyMacrosFilter(entity.filter, rec);
    if (entity.links) {
        for (const l of entity.links) {
            (0, exports.applyMacros)(l, rec);
        }
    }
};
exports.applyMacros = applyMacros;
const applyMacrosFilter = (filter, rec) => {
    if (filter.conditions) {
        for (const c of filter.conditions) {
            if (c.value && c.value.startsWith && c.value.startsWith("{{") && c.value.endsWith("}}")) {
                const name = c.value.substring(2, c.value.length - 2);
                if (rec) {
                    let v = (typeof rec === "function") ? rec(name) : rec[name];
                    if (v && v.id)
                        v = v.id;
                    if (!v)
                        c.operator = "null";
                    c.value = v;
                }
            }
        }
    }
    if (filter.filters) {
        for (const f of filter.filters)
            applyMacrosFilter(f, rec);
    }
};
const mergeFetch = (q, mf, mergeAttributes) => {
    if (!mf)
        return;
    mf = JSON.parse(JSON.stringify(mf)); // copy
    if (mf.distinct === true)
        q.distinct = true;
    const updateConditionAlias = (filter) => {
        if (filter.conditions) {
            for (const cond of filter.conditions) {
                if (cond.entityName)
                    cond.entityName = "x" + cond.entityName;
            }
        }
        if (filter.filters) {
            for (const childFilter of filter.filters)
                updateConditionAlias(childFilter);
        }
    };
    const updateLinkAlias = (entity) => {
        if (entity.links) {
            for (const link of entity.links) {
                link.alias = "x" + link.alias;
                updateLinkAlias(link);
            }
        }
    };
    const mergedFilter = mf.entity.filter;
    if (mergedFilter) {
        updateConditionAlias(mergedFilter);
        const combinedFilter = q.entity.filter ?
            { filters: [q.entity.filter, mergedFilter] } :
            mergedFilter;
        q.entity.filter = combinedFilter;
    }
    const mergedLinks = mf.entity.links;
    if (mergedLinks) {
        updateLinkAlias(mf.entity);
        q.entity.links = (q.entity.links || []).concat(mergedLinks);
    }
    if (mergeAttributes && mf.entity.attributes) {
        const newAttrs = mf.entity.attributes;
        let attrs = q.entity.attributes;
        attrs = attrs.filter(x => !newAttrs.find(y => y.attribute === x.attribute));
        attrs.push(...mf.entity.attributes);
        q.entity.attributes = attrs;
    }
};
exports.mergeFetch = mergeFetch;
const makeFetch = (name, attrs, conditions) => {
    const f = {
        entity: {
            name: name,
        }
    };
    if (attrs === undefined)
        f.entity.allattrs = true;
    else
        f.entity.attributes = attrs.map(a => ({ attribute: a }));
    if (conditions) {
        f.entity.filter = {
            conditions: conditions.map(cond => ({ attribute: cond[0], operator: cond[1], value: cond[2] }))
        };
    }
    return f;
};
exports.makeFetch = makeFetch;
