import { useMemo, useCallback, useEffect, useState, useRef, useContext } from "react";
import { Fetch, applyMacros, mergeFetch } from "shared/fetch";
import { IExecuteRequest, ILookupValue } from "shared/schema";
import { IFormField } from "../../AppSchema";
import { AppLink } from "../../components/AppLink";
import { BaseDropDown } from "../../components/BaseDropDown";
import { Loading } from "../../components/Loading";
import { useDerivedState } from "../../hooks/useDerivedState";
import { DataService } from "../../service";
import { PanelProps, PanelPropsEventType } from "../panels/PanelProps";
import { BaseObjectList } from "../../objectList/BaseObjectList";
import { MetaContext, checkPermissions } from "../../AppState";
import { PERMISSION_CREATE } from "shared/permissions";

export const ManyToManyField = (props: PanelProps & { field: IFormField }) => {

	const metadata = useContext(MetaContext);
	const [loading, setLoading] = useDerivedState(props.id !== "0", [props.id, props.meta]);
	const [rows, setRows] = useDerivedState([] as any[], [props.id, props.meta]);

	const fieldConfig = (props.field.customComponent as string).split(';');
	const [otherEntity, lookup1, intersectEntity, lookup2] = (fieldConfig[1] || "").split(".");
	//const extraFilter = (fieldConfig[2] || "").split(".");
	// const lookup1 = "systemuserid";
	// const lookup2 = "roleid";
	// const intersectEntity = "inu_userroles";
	// const otherEntity = "inu_role"
	const alias = "mm";
	const selectedName = alias + "__" + lookup1;

	// const extraConditions: any[] = [];
	// if (extraFilter && extraFilter.length > 1) {
	// 	const q = fetch;
	// 	const c = extraConditions
	// 	const r = props.getRecord();
	// 	//const extraFilter = propMeta.options;
	// 	for (let i = 0; i < extraFilter.length; i += 2) {
	// 		let v = r[extraFilter[i + 1]];//props.getRecordProperty(extraFilter[i + 1]);
	// 		if (v && v.id) // deconstruct lookup
	// 			v = v.id;
	// 		c.push({ attribute: extraFilter[i], operator: "eq", value: v });
	// 	}
	// }

	const getQuery = (selectedOnly?: boolean) => {
		const fetch: Fetch = {
			entity: {
				name: otherEntity,
				attributes: ["id", "name"].map(x => ({ attribute: x })),
				links: [{
					name: intersectEntity,
					to: lookup2,
					from: "id",
					alias: "mm",
					type: selectedOnly ? "inner" : "outer",
					attributes: [{ attribute: lookup1 }],
					filter: {
						conditions: [{ attribute: lookup1, operator: "eq", value: props.id }]
					},
				}],
				orders: [{ attribute: "name" }]
			}
		};
		if (props.id === "0")
			fetch.entity.links = [];
		
		const extraFetch = props.field.extraFetch;
		if (!selectedOnly && extraFetch) {
			const mf = JSON.parse(JSON.stringify(extraFetch));
			mergeFetch(fetch, mf, true);
			applyMacros(fetch.entity, props.getRecord());
		}
		return fetch;
	}

	const fetchRecords = async (selectedOnly?: boolean) => {
		const fetch = getQuery(selectedOnly)
		const records = await DataService.retrieveMultiple(fetch);
		return records;
	}

	useEffect(() => {
		if (props.id !== "0") {
			const exe = async () => {
				const records = await fetchRecords(true);
				setRows(records);
				setLoading(false);
			}
			exe();
		}
	}, [props.getRecord, props.field.customComponent]);

	useEffect(() => {
		const eventHandler = async (eventName: PanelPropsEventType) => {

			if (eventName === "afterSave") {
				try {
					const target = { id: props.getRecord().id, name: props.meta.logicalName };
					const toSave: IExecuteRequest[] = [];
					for (const row of rows) {
						if (row.isModified) {
							const obj = {
								[lookup1]: target as ILookupValue,
								[lookup2]: { id: row.id, name: otherEntity } as ILookupValue
							};
							toSave.push({
								name: intersectEntity, operation: row[selectedName] ? "create" : "delete", object: obj
							});
							row.isModified = false;
						}
					}
					if (toSave.length > 0)
						await DataService.executeMultiple(toSave);
				}
				catch (e) {
					alert(e);
					return false;
				}
			}
			return true;
		}

		let cancel = false;
		const events = props.events || [];
		events.push(eventHandler);
		const unregister = () => events.splice(events.indexOf(eventHandler), 1);
		return () => {
			cancel = true;
			unregister();
		};
	},[props.meta, rows])

	const toggleSelected = (row: any, isSelected: boolean) => {
		row = rows.find(x => x.id === row.id) || row;
		const wasSelected = !!row[selectedName]
		if (wasSelected !== isSelected) {
			if (isSelected)
				row[selectedName] = { id: props.id, name: props.meta.logicalName };
			else
				row[selectedName] = undefined;
			row.isModified = true;
			//const newRows = rows.filter(x => x !== row && x.id !== row.id);
			const newRows = [...rows];
			if (rows.indexOf(row) < 0) {
				newRows.push(row);
				const s = { sensitivity: 'base' };
				newRows.sort((a, b) => a.name.localeCompare(b.name, undefined, s));
			}
			setRows(newRows);
			props.setRecord({ ...props.getRecord() }); // mark form dirty
		}
	}

	const formatter = (row: any, fieldName: string, style: any) => {
		row = rows.find(x => x.id === row.id) || row;
		const isSelected = !!row[selectedName];//selected.indexOf(row.name) >= 0;
		//const selIndex = rows.findIndex(x => x.id === row.id);
		//const isSelected = selIndex >= 0;
		const icon = <i className={"fa " + (isSelected ? "fa-circle-check" : "fa-regular fa-circle")}
			style={{ color: isSelected ? "blue" : "var(--fore-color)" }} />;
	
		const label = row.name;
		return <a href="#" style={{ textDecoration: "none", padding: "2px", color: "var(--fore-color)", display: "block", width: "100%", height: "100%" }} onClick={e => { e.preventDefault(); toggleSelected(row, !isSelected); }}>{icon} {label}</a>;
	};
		
	const valueToLink = useCallback(() => {
		if (rows && rows.length) {
			return <div style={{display:"flex", flexWrap:"wrap"}}>
				{rows.filter(x => !!x[selectedName]).map(x => {
					return <AppLink to={"/edit/"+otherEntity+"/"+x.id}
						className="lookupInputLink lookupLink">{x.name || "Unknown"}</AppLink>
				})}
			</div>
		}
		return undefined;
	}, [rows]);
		
	const key = useMemo(() => new Date().valueOf(), [props.meta]);
		
	if (loading)
		return <Loading />
	
	const onChange = (newValue: any) => {
		if (newValue === null) { // delete, remove last
			const row = [...rows].reverse().find((x:any) => !!x[selectedName]);
			if (row)
				toggleSelected(row, false);
		}
	}

	const editMode = (props.field as any).enabled !== false && checkPermissions(metadata, {}, intersectEntity, PERMISSION_CREATE);
	
	return <BaseDropDown key={key} value={"1"}
		onChange={onChange}
		disabled={!editMode}
		valueToLink={valueToLink}
		dropDownProps={{ rows: rows, formatter: formatter, labelField: "name", loadRows: fetchRecords, getQuery: getQuery }}
		DropDownElement={MulitSelectEntityDropDown}
	/>
}

const MulitSelectEntityDropDown = (props: {
	search: string;

	formatter: (row: any, fname: string, style: any) => React.ReactNode;
	getQuery: () => Fetch;
}) => {

	const onRenderCell = (row: any, field: string) => {
		if (field === "name")
			return props.formatter(row, field, undefined);
		return undefined;
	}

	const q = useRef(props.getQuery()); // BaseObjectList will _not_ reload the list if the query does not change. getQuery will produce a new fetch object each time called.
	const query = useMemo(() => {
		const fetch = JSON.parse(JSON.stringify(q.current)) as Fetch;
		const search = props.search;
		if (search) {
			fetch.entity.filter = {
				conditions: [
					{ attribute: "name", operator: "like", value: search + "%" }
				]
			};
		}
		return fetch;
	}, [q.current, props.search]);
	const listColumns = query.entity.attributes!.filter(x => x.attribute !== "id").map(x => ({ attribute: x.attribute }));
	return <BaseObjectList onRenderCell={onRenderCell} query={query} onClick={()=>{}} listColumns={listColumns} noHeader={true} disableLookupClick={true} />	
} 