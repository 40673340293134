import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "../components/IconButton";
import { Loading } from "../components/Loading";
import { MainFooter, MainHeader } from "./MainPage";

const TrialPanel = () => {
	const [login, setLogin] = useState({ person: "", email: "", password: "", password2: "", organization: "" });
	const [isSending, setSending] = useState(false);
	const [status, setStatus] = useState({ msg: "", err: false });
	const navigate = useNavigate();

	const handleSubmit = async () => {
		//e.preventDefault();
	
		const { email, password } = login;
		if (!email || !password) return;

		setSending(true);
		const data = new URLSearchParams();
		data.append("person", login.person);
		data.append("username", email);
		data.append("password", password);
		data.append("organization", login.organization);
		const resp = await fetch("/api/trial", {
			method: "POST",
			body: data
		});

		try {
			const text = await resp.text();
			if (!resp.ok) {
				setStatus({ msg: "Invalid Username or password", err: true });
			}
			else {
				if (text === "WAIT") {
					setTimeout(handleSubmit, 2000);
					return;
				}
				setStatus({ msg: "Organiztion Created!", err: false });
				setTimeout(() => navigate("/", { replace: true }), 700);
			}
		}
		catch {
			//resp.ok
		}
		setSending(false);
	}
	let pwdMatch = true;
	if (login.password && login.password2 && login.password !== login.password2) {
		pwdMatch = false;
	}

	// const onLoginWithGoogle = () => {
	// 	if (window.location.hostname === "localhost") {
	// 		window.location.href = "http://localhost:3001/auth/google/signup?redirectUrl=" + encodeURIComponent("http://localhost:3000");
	// 	} else {
	// 		window.location.pathname = "/auth/google/signup";
	// 	}
	// }

	return (
		<form><fieldset className="loginForm" disabled={isSending}>
				
			<div className="formItem">
				<label className="formLabel" htmlFor="organization">Your&nbsp;Organization</label>
				<input id="organization" type="text" value={login.organization} onChange={e => setLogin({ ...login, organization: e.target.value })} />
			</div>
			<div className="formItem">
				<label className="formLabel" htmlFor="email">Your&nbsp;Name</label>
				<input id="email" type="email" value={login.person} onChange={e => setLogin({ ...login, person: e.target.value })} />
			</div>
			<div className="formItem">
				<label className="formLabel" htmlFor="email">Email</label>
				<input id="email" type="email" value={login.email} onChange={e => setLogin({ ...login, email: e.target.value })} />
			</div>
			<div className="formItem">
				<label className="formLabel" htmlFor="password">Password</label>
				<input id="password" type="password" value={login.password} onChange={e => setLogin({ ...login, password: e.target.value })} />
			</div>
			<div className="formItem">
				<label className="formLabel" htmlFor="password">Confirm&nbsp;Password</label>
				<input id="password" type="password" value={login.password2} onChange={e => setLogin({ ...login, password2: e.target.value })} />
			</div>
			<IconButton icon="rocket" disabled={!login.person || !login.email || !login.password || !login.password2 || !pwdMatch || !login.organization}
				style={{ width: "auto", margin: "0" }}
				onClick={handleSubmit} label="Start me up!" />
		
			{/* <LoginWithGoogle label="Sign-up with Google" onClick={onLoginWithGoogle} /> */}
			{isSending && <Loading />}
			{status.msg && <div style={{ color: status.err ? "red" : "green" }}>{status.msg}</div>}
			{!pwdMatch && <div style={{ color: "red" }}>Passwords do not match.</div>}
		</fieldset></form>
	);
}


export const TrialForm = () => {
	return (<div className='mainContainer'> <div className='main'>
		<MainHeader link="/main" linkName="Home"/>
		<div className='loginHeroBkg'>
			<div className='mainHero'>
				<div className="loginFormPanel">
					<div className="loginFormLeft">
						<span>Start Trial
							<br /><span style={{ fontSize: "14px", lineHeight: "16px" }}>Or <a href="/login">login</a> if you have an organization.</span>
						</span>
					</div>
					<div className="loginFormContainer">
						<TrialPanel />
					</div>
				</div>
			</div>
		</div>
		<MainFooter/>
	</div>
	</div>)
}